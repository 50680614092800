import axios from "axios";
import { BASE_URL } from "../config/config";

//*** Fetching Home Data ***
export const fetchHome = async () => {
  const response = await axios({
    method: "post",
    url: BASE_URL,
    data: { model: "home", pageType: "1" },
  });
  const data = response.data;
  if (response.data.returnCode !== 1) {
    return;
  }
  return data;
};

//*** Fetching Story Data ***
export const fetchStory = async () => {
  const response = await axios({
    method: "post",
    url: BASE_URL,
    data: { model: "story", pageType: "3" },
  });
  const data = response.data;
  if (response.data.returnCode !== 1) {
    return;
  }
  return data;
};

//*** Fetching Fuels Data ***
export const fetchFuels = async () => {
  const response = await axios({
    method: "post",
    url: BASE_URL,
    data: { model: "fuel", pageType: "8" },
  });
  const data = response.data;
  if (response.data.returnCode !== 1) {
    return;
  }
  return data;
};

//*** Fetching Fuel Card Data ***
export const fetchFuelCard = async () => {
  const response = await axios({
    method: "post",
    url: BASE_URL,
    data: { model: "fuelCard", pageType: "7" },
  });
  const data = response.data;
  if (response.data.returnCode !== 1) {
    return;
  }
  return data;
};

//*** Fetching Supplier Data ***
export const fetchSupplier = async () => {
  const response = await axios({
    method: "post",
    url: BASE_URL,
    data: { model: "supplier", pageType: "1" },
  });
  const data = response.data;
  if (response.data.returnCode !== 1) {
    return;
  }
  return data;
};

//*** Fetching Contact Data ***
export const fetchContact = async () => {
  const response = await axios({
    method: "post",
    url: BASE_URL,
    data: { model: "contactUs", pageType: "9" },
  });
  const data = response.data;
  if (response.data.returnCode !== 1) {
    return;
  }
  return data;
};
//*** Fetching Location Data ***
export const fetchlocation = async () => {
  const response = await axios({
    method: "post",
    url: BASE_URL,
    data: {"model":"location", pageType:"2"},
  });
  const data = response.data;
  if (response.data.returnCode !== 1) {
    return;
  }
  return data;
};
//*** Fetching Convenience Data ***
export const fetchconvenience = async () => {
  const response = await axios({
    method: "post",
    url: BASE_URL,
    data: {"model":"convenience",pageType:"4"}
  });
  const data = response.data;
  if (response.data.returnCode !== 1) {
    return;
  }
  return data;
};
//*** Fetching Community Data ***
export const fetchcommunity = async () => {
  const response = await axios({
    method: "post",
    url: BASE_URL,
    data: {"model":"community","pageType":"5"}
  });
  const data = response.data;
  if (response.data.returnCode !== 1) {
    return;
  }
  return data;
};
//*** Fetching Community Data ***
export const fetchbudgetboost = async () => {
  const response = await axios({
    method: "post",
    url: BASE_URL,
    data: {"model":"budgetBoost","pageType":"11"}
  });
  const data = response.data;
  if (response.data.returnCode !== 1) {
    return;
  }
  return data;
};

//*** Fetching Partner With Us Data ***
export const fetchpartner = async () => {
  const response = await axios({
    method: "post",
    url: BASE_URL,
    data: {"model":"partnerWithUs","pageType":"6"}
  });
  const data = response.data;
  if (response.data.returnCode !== 1) {
    return;
  }
  return data;
};

//*** Fetching Footer Data ***
export const fetchFooter = async () => {
  const response = await axios({
    method: "post",
    url: BASE_URL,
    data: { model: "footer", pageType: "All" },
  });
  const data = response.data;
  if (response.data.returnCode !== 1) {
    return;
  }
  return data;
};

//*** Fetching Employment Data ***
export const fetchEmployment  =async()=>{
  const response = await axios({
    method:"post",
    url:BASE_URL,
    data:{"model":"employment","pageType":"12"}
  });
  const data = response.data;
  if(response.data.returnCode!==1){
    return;
  }
  return data;
}
