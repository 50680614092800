import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./components/Layout";
import HomePage from "./pages/HomePage";
import Locations from "./pages/Locations";
import Story from "./pages/Story";
import Convenience from "./pages/Convenience";
import Community from "./pages/Community";
import PartnerWithUS from "./pages/PartnerWithUS";
import FuelCards from "./pages/FuelCards";
import Fuels from "./pages/Fuels";
import Error404 from "./pages/404Error";
import ContactUs from "./pages/ContactUs";
import Suppliers from "./pages/Suppliers";
import ChristmasGiveaway from "./pages/ChristmasGiveaway";
import Budgetboost from "./pages/Budgetboost";
import Employment from "./pages/Employment";
import Comp from "./pages/Comp";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "locations",
        element: <Locations />,
      },
      {
        path: "our-story",
        element: <Story />,
      },
      {
        path: "convenience",
        element: <Convenience />,
      },
      {
        path: "community",
        element: <Community />,
      },
      {
        path: "gascorp",
        element: <PartnerWithUS />,
      },
      {
        path: "fuel-1-card",
        element: <FuelCards />,
      },
      {
        path: "fuels",
        element: <Fuels />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "suppliers",
        element: <Suppliers />,
      },
      {
        path : "budget-boost",
        element : <Budgetboost />
      },
      {
        path : "giveaway",
        element:<ChristmasGiveaway/>
      },
      {
        path:"employment",
        element:<Employment/>
      },
      {
        path:"comp",
        element:<Comp/>
      },
      {
        path : "*",
        element : <Error404 />
      },
    ],
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
