import React, { useState, useEffect, useRef } from "react";
import { fetchFuels } from "../util/fetch";
import styles from "./pages.module.css";
import { NavLink } from "react-router-dom";
import { SITE_URL, VIDEO_URL } from "../config/config";
import { Helmet } from "react-helmet";
// import fuelVideo from "../assets/videos/fuel.mp4";

const Fuels = () => {
  const [fuelData, setFuelData] = useState({
    bannerData: {},
    fuel: [],
  });
  const videoRef = useRef(null);
  useEffect(() => {
    document.title = "Fuels | Budget Petrol";
    const fetchData = async () => {
      const response = await fetchFuels();
      setFuelData({
        ...fuelData,
        bannerData: response.data.banner,
        fuel: response.data.fuel,
      });
    };
    fetchData();
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);
  return (
    <>
      <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Fuels | Budget Petrol" />
        <meta property="og:url" content={`${SITE_URL}fuels`} />
        <meta property="og:site_name" content="BUDGET PETROL" />
        <meta property="article:modified_time" content="2024-05-22T06:01:40+00:00" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {/* Banner Section */}
      <div
        className={`${styles.inner_banner_section} ${styles.banner_vid_sec}`}
        // style={{ backgroundImage: `url(${fuelData.bannerData.banner_image})` }}
      >
        <div className={styles.video_section}>
          <video loop autoPlay muted playsInline ref={videoRef} className={styles.video_content}>
            <source src={`${VIDEO_URL}/fuel.mp4`} type="video/mp4" />
          </video>
        </div>
        <div className={`${styles.container} ${styles.container_vid_div}`}>
          <div className={styles.banner_heading}>
            <h1 className={styles.banner_txt}>
              {fuelData.bannerData.banner_title}
            </h1>
            <div className={styles.breadcrumb_div}>
              <p>
                <NavLink to="/">Home </NavLink>
                <span>Fuels</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Our Fuels */}
      <div className={`${styles.our_fuel_section} ${styles.fuels_section}`}>
        <div className={styles.fuel_heading}>
          <h2 className={`${styles.fuel_txt} ${styles.hello}`}>
            Our <span>Fuels</span>
          </h2>
        </div>
        <div className={styles.float_style}>
          {fuelData.fuel.length > 0 &&
            fuelData.fuel.slice().reverse().map((item, index) => (
              <>
                {index % 2 == 0 ? (
                  <div className={styles.shop_local}>
                    <div className={styles.container}>
                      <div className={styles.fuel_main}>
                        <div className={styles.shop_local_left}>
                          <h2 className={styles.fuel_heading}>
                            {item.ourfuels_title.split(" ").slice(0, 1)}{" "}
                            <span>
                              {item.ourfuels_title.split(" ").slice(1, 2)}{" "}
                              {item.ourfuels_title.split(" ").slice(2)}
                            </span>
                          </h2>
                          <img
                            src={item.ourfuels_image}
                            alt={item.ourfuels_title}
                            className={styles.mobile_view}
                          />
                          <p
                            className={styles.shop_local_txt}
                            dangerouslySetInnerHTML={{
                              __html: item.ourfuels_content,
                            }}
                          ></p>
                          <a href={item.ourfuels_link} target="_blank">
                            <button className={styles.shop_local_btn}>
                              Download Material Safety Data Sheet
                            </button>
                          </a>
                        </div>
                        <div className={styles.shop_local_right}>
                          <img
                            src={item.ourfuels_image}
                            alt={item.ourfuels_title}
                            className={styles.desktop_view}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.shop_local}>
                    <div className={styles.container}>
                      <div className={styles.fuel_main}>
                        <div className={styles.shop_local_right}>
                          <img
                            src={item.ourfuels_image}
                            alt={item.ourfuels_title}
                            className={styles.desktop_view}
                          />
                        </div>
                        <div className={styles.shop_local_left}>
                          <h2 className={styles.fuel_heading}>
                            {item.ourfuels_title.split(" ").slice(0, 1)}{" "}
                            <span>
                              {item.ourfuels_title.split(" ").slice(1, 2)}{" "}
                              {item.ourfuels_title.split(" ").slice(2)}
                            </span>
                          </h2>
                          <img
                            src={item.ourfuels_image}
                            alt={item.ourfuels_title}
                            className={styles.mobile_view}
                          />
                          <p
                            className={styles.shop_local_txt}
                            dangerouslySetInnerHTML={{
                              __html: item.ourfuels_content,
                            }}
                          ></p>
                          <a href={item.ourfuels_link} target="_blank">
                            <button className={styles.shop_local_btn}>
                              Download Material Safety Data Sheet
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
        </div>
      </div>
    </>
  );
};

export default Fuels;
