import React, { useEffect, useState } from "react";
import { fetchStory } from "../util/fetch";
import styles from "./pages.module.css";
import ImageSlider from "../components/ImageSlider";
import { NavLink } from "react-router-dom";
import { SITE_URL } from "../config/config";
import { Helmet } from "react-helmet";

const Story = () => {
  const [storyData, setStoryData] = useState({
    bannerData: {},
    story: [],
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [numberOfSlides, setNumberOfSlides] = useState(4);
  const [numberOfSlidesAbout,setNumberOfSlidesAbout] = useState(3);
  // FETCH STORY DATA FROM API
  useEffect(() => {
    document.title="Our Story | Budget Petrol"
    const fetchData = async () => {
      const response = await fetchStory();
      setStoryData({
        ...storyData,
        bannerData: response.data.banner,
        story: response.data.story,
      });
    };
    fetchData();
  }, []);
  // SETIING UP SLIDER IMAGES BASED ON SCREEN SIZE
  const updateNumOfSlides = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 480) {
      setNumberOfSlides(1);
      setNumberOfSlidesAbout(1);
    }
    else if (screenWidth <= 1099) {
      setNumberOfSlides(3);
      setNumberOfSlidesAbout(3);
    }
    else {
      setNumberOfSlides(4);
      setNumberOfSlidesAbout(3);
    }
  };
  useEffect(() => {
    updateNumOfSlides();
    window.addEventListener("resize", updateNumOfSlides);
    return () => {
      window.removeEventListener("resize", updateNumOfSlides);
    };
  }, []);
  return (
    <>
      <Helmet>
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Our Story | Budget Petrol" />
      <meta property="og:url" content={`${SITE_URL}our-story`} />
      <meta property="og:site_name" content="BUDGET PETROL" />
      <meta property="article:modified_time" content="2024-05-22T06:01:40+00:00" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:label1" content="Est. reading time" />
      <meta name="twitter:data1" content="3 minutes" />
      </Helmet>
      {/* Banner Section */}
      <div
        className={styles.inner_banner_section}
        style={{ backgroundImage: `url(${storyData.bannerData.banner_image})` }}
      >
        <div className={styles.container}>
          <div className={styles.banner_heading}>
            <h1 className={styles.banner_txt}>
              {storyData.bannerData.banner_title}
            </h1>
            <div className={styles.breadcrumb_div}>
              <p>
                <NavLink to="/">Home </NavLink>
                <span>Our Story</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Our Story Section */}
      <div className={styles.our_story_section}>
        <div className={styles.container}>
          <div className={styles.fuel_heading}>
            <h2 className={styles.fuel_txt}>
              Our <span>Story</span>
            </h2>
            <p>
              From humble beginnings to over 60 stores.
            </p>
          </div>
          <div className={styles.shop_local}>
            <div className={styles.shop_local_left}>
              {storyData.story.length > 0 && (
                <h2 className={styles.shop_local_heading}>
                  {storyData.story[2].story_title.slice(0, 13)}
                  {"'"}
                  {storyData.story[2].story_title.slice(19, 20)}
                  <span>{storyData.story[2].story_title.slice(20)}</span>
                </h2>
              )}
              {storyData.story.length > 0 && (
                <>
                  <img
                    src={storyData.story[2].story_titel_image}
                    alt="Budget Petrol's History"
                    className={styles.mobile_view}
                  />
                  <p
                    className={styles.shop_local_txt}
                    dangerouslySetInnerHTML={{
                      __html: storyData.story[2].story_content,
                    }}
                  ></p>
                </>
              )}
            </div>
            <div className={styles.shop_local_right}>
              {storyData.story.length > 0 && (
                <img
                  src={storyData.story[2].story_titel_image}
                  alt="Budget Petrol's History"
                  className={styles.desktop_view}
                />
              )}
            </div>
          </div>
          <div className={styles.slider_wrap}>
            <div className={styles.slider}>
              {storyData.story.length > 0 && (
                <ImageSlider
                  slides={storyData.story[2].bbs_box}
                  setCurrentIndex={setCurrentIndex}
                  numberOfSlides={numberOfSlides}
                  sliderType="OurStory"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* About Us Section */}
      <div className={`${styles.gascorp_section} ${styles.gasc_section}`}>
        <div className={styles.container}>
          <div className={styles.gascorp_story}>
            {storyData.story.length > 0 && (
              <>
                <div className={styles.about_left}>
                  <img
                    src={storyData.story[1].story_titel_image}
                    alt={storyData.story[1].story_title}
                  />
                </div>
                <div className={styles.about_right}>
                  <h2>{storyData.story[1].story_title}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: storyData.story[1].story_content,
                    }}
                  ></p>
                </div>
              </>
            )}
          </div>
          <div className={styles.slider_wrap}>
            <div className={styles.slider}>
              {storyData.story.length > 0 && (
                <ImageSlider
                  slides={storyData.story[1].bbs_box}
                  setCurrentIndex={setCurrentIndex}
                  numberOfSlides={numberOfSlidesAbout}
                  sliderType="AboutUs"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Future Section */}
      <div className={`${styles.career_section} ${styles.story_carrier}`}>
        <div className={styles.container}>
          <div className={styles.career_heading}>
            {storyData.story.length > 0 && (
              <>
                <h2 className={styles.career_heading_txt}>
                  {storyData.story[0].story_title.slice(0, 13)}
                  {"'"}
                  {storyData.story[0].story_title.slice(19, 20)}
                  <span>{storyData.story[0].story_title.slice(20)}</span>
                </h2>
                <p
                  className={styles.career_txt}
                  dangerouslySetInnerHTML={{
                    __html: storyData.story[0].story_content,
                  }}
                ></p>
              </>
            )}
          </div>
          <div className={styles.slider_wrap}>
            <div className={styles.slider}>
              {storyData.story.length > 0 && (
                <ImageSlider
                  slides={storyData.story[0].bbs_box}
                  setCurrentIndex={setCurrentIndex}
                  numberOfSlides={numberOfSlides}
                  sliderType="BudgetFuture"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Story;
