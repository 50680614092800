import React from "react";
import styles from "./component.module.css"
const BudgetInfoModal = ({ item, infoType, modalVisible, setModalVisible }) => {
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const setModal = () =>{
    setModalVisible(false);
    document.body.classList.remove("no_scroll","modal_set");
    document.getElementById("address_div").classList.remove("no_scroll")
  }
  const modalClass = infoType==="hours" ? `${styles.info_modal_hours}` : infoType ==="services" ? `${styles.info_modal_services}` : `${styles.info_modal_products}` 
  if (!modalVisible) return null;
  return (
    <div className={modalClass}>
      <button onClick={()=>setModal()}>CLOSE</button>
      {infoType === "hours" && (
        <div className={styles.opening_hours}>
          {item.openingHours.map((hours, index) => (
            <p>
              <a>
                {/* <span>{day} : </span> */}
                {hours}
              </a>
            </p>
          ))}
        </div>
      )}
      {infoType === "services" && (
        <div className={styles.services_available}>
          <ul>
          {item.serviceAvailable.map((service, index) => (
            <li>
              <a>{service}</a>
            </li>
          ))}
          </ul>
        </div>
      )}
      {infoType === "products" && (
        <div className={styles.products_available}>
          <ul>
            {item.productsAvailable.map((product,index)=>(
                <li>
                    <a>{product}</a>
                </li>
            ))}</ul>
        </div>
      )}
    </div>
  );
};

export default BudgetInfoModal;
