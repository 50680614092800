import React, { useState, useEffect, useRef } from "react";
import { fetchconvenience } from "../util/fetch";
import styles from "./pages.module.css";
import { NavLink } from "react-router-dom";
import { SITE_URL, VIDEO_URL } from "../config/config";
import { Helmet } from "react-helmet";
import ImageSlider from "../components/ImageSlider";

const Convenience = () => {
  const [convenienceData, setconvenienceData] = useState({
    bannerData: {},
    convenience: [],
    supplier: [],
  });
  const [numberOfSlides, setNumberOfSlides] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRef = useRef(null);
  // const updateNumOfSlides = () => {
  //   const screenWidth = window.innerWidth;
  //   if (screenWidth < 767) {
  //     setNumberOfSlides(1);
  //   } else if (screenWidth <= 1024) {
  //     setNumberOfSlides(2);
  //   } else {
  //     setNumberOfSlides(3);
  //   }
  // };
  // useEffect(() => {
  //   updateNumOfSlides();
  //   window.addEventListener("resize", updateNumOfSlides);
  //   return () => {
  //     window.removeEventListener("resize", updateNumOfSlides);
  //   };
  // }, []);
  useEffect(() => {
    document.title = "Convenience | Budget Petrol";
    const fetchData = async () => {
      const response = await fetchconvenience();
      setconvenienceData({
        ...convenienceData,
        bannerData: response.data.banner,
        convenience: response.data.convenience,
        supplier: response.data.supplier,
      });
    };
    fetchData();
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);
  return (
    <>
      <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Convenience | Budget Petrol" />
        <meta property="og:url" content={`${SITE_URL}convenience`} />
        <meta property="og:site_name" content="BUDGET PETROL" />
        <meta
          property="article:modified_time"
          content="2024-05-22T06:01:40+00:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="2 minutes" />
      </Helmet>
      {/* Banner Section */}
      <div
        className={`${styles.inner_banner_section} ${styles.banner_vid_sec}`}
        // style={{
        //   backgroundImage: `url(${convenienceData.bannerData.banner_image})`,
        // }}
      >
        <div className={styles.video_section}>
          <video
            loop
            autoPlay
            muted
            playsInline
            ref={videoRef}
            className={styles.video_content}
          >
            <source
              src={`${VIDEO_URL}/convenience.mp4`}
              type="video/mp4"
            />
          </video>
        </div>
        <div className={`${styles.container} ${styles.container_vid_div}`}>
          <div className={styles.banner_heading}>
            <h1 className={styles.banner_txt}>
              {convenienceData.bannerData.banner_title}
            </h1>
            <div className={styles.breadcrumb_div}>
              <p>
                <NavLink to="/">Home </NavLink>
                <span>Convenience</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Convenience Section  */}
      <div
        className={`${styles.our_fuel_section} ${styles.convenience_banener_down}`}
      >
        <div
          className={`${styles.fuel_heading} ${styles.convenience_banener_heading}`}
        >
          <h2 className={`${styles.fuel_txt} ${styles.our_convenince}`}>
            Our <span>Convenience</span>
          </h2>
        </div>
        <div className={styles.float_style}>
          <div className={styles.shop_local_conviance}>
            <div className={styles.container}>
              {convenienceData.convenience.length > 0 && (
                <div className={styles.fuel_main}>
                  <div className={styles.shop_local_left}>
                    <h2 className={styles.fuel_heading}>
                      {convenienceData.convenience[0].bbs_title}{" "}
                    </h2>
                    <img
                      src={convenienceData.convenience[0].bbs_image}
                      alt={convenienceData.convenience[0].bbs_title}
                      className={styles.mobile_view}
                    />
                    <p
                      className={styles.shop_local_txt}
                      dangerouslySetInnerHTML={{
                        __html: convenienceData.convenience[0].bbs_content,
                      }}
                    ></p>
                  </div>
                  <div className={styles.shop_local_right}>
                    <img
                      src={convenienceData.convenience[0].bbs_image}
                      alt={convenienceData.convenience[0].bbs_title}
                      className={styles.desktop_view}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={`${styles.float_style} ${styles.float_sec}`}>
          <div className={styles.container}>
            {convenienceData.convenience.length > 0 && (
              <div
                className={`${styles.fuel_main} ${styles.shop_local_conviance}`}
              >
                {/* <div className={styles.shop_local_right}> */}
                  {/* <img
                    src={convenienceData.convenience[1].bbs_image}
                    alt={convenienceData.convenience[1].bbs_title}
                    className={styles.desktop_view}
                  /> */}
                  <div className={styles.desktop_view}>
                  <ImageSlider
                    slides={convenienceData.convenience[1].bbs_box}
                    setCurrentIndex={setCurrentIndex}
                    numberOfSlides={numberOfSlides}
                    sliderType="Convenience"
                  />
                  </div>
                {/* </div> */}
                <div className={styles.shop_local_left}>
                  <h2 className={styles.fuel_heading}>
                    {convenienceData.convenience[1].bbs_title.slice(0, 28)}
                    {convenienceData.convenience[1].bbs_title.slice(32)}
                  </h2>
                  {/* <img
                    src={convenienceData.convenience[1].bbs_image}
                    alt={convenienceData.convenience[1].bbs_title}
                    className={styles.mobile_view}
                  /> */}
                  <div className={styles.mobile_view}>
                  <ImageSlider
                    slides={convenienceData.convenience[1].bbs_box}
                    setCurrentIndex={setCurrentIndex}
                    numberOfSlides={numberOfSlides}
                    sliderType="Convenience"
                  />
                  </div>
                  <p
                    className={styles.shop_local_txt}
                    dangerouslySetInnerHTML={{
                      __html: convenienceData.convenience[1].bbs_content,
                    }}
                  ></p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Supplier Image Section */}
      <div className={`${styles.supplier_page} ${styles.supplier_page_update}`}>
        <div className={styles.container}>
          <div className={styles.up_main_heading}>
            <h2 className={styles.upper_main_heading}>Suppliers</h2>
          </div>
          <div className={styles.supplier_gallery}>
            {convenienceData.supplier.length > 0 &&
              convenienceData.supplier.slice().reverse().map((image, index) => (
                <div className={styles.image_wrap}>
                  {" "}
                  <a href={`${image.supplier_link}`} target="_blank">
                    <img src={image.supplier_image} />
                  </a>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Convenience;
