import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const BreadCrumbs = () => {
  const location = useLocation();
  return (
    <div>
      <div>
        <NavLink to="/">Home</NavLink>
        {location.pathname.includes("/locations") && (
          <>
            <p>{">>"}<NavLink to="/locations">Locations</NavLink></p>
            
          </>
        )}
        {location.pathname.includes("/story") && (
          <>
            <p>{">>"}<NavLink to="/story">Story</NavLink></p>
            
          </>
        )}
        {location.pathname.includes("/convenience") && (
          <>
            <p>{">>"}<NavLink to="/convenience">Convenience</NavLink></p>
            
          </>
        )}
        {location.pathname.includes("/community") && (
          <>
            <p>{">>"}<NavLink to="/community">Community</NavLink></p>
            
          </>
        )}
        {location.pathname.includes("/partner-with-us") && (
          <>
            <p>{">>"}<NavLink to="/partner-with-us">Partner With Us</NavLink></p>
            
          </>
        )}
        {location.pathname.includes("/fuel-cards") && (
          <>
            <p>{">>"}<NavLink to="/fuel-cards">Fuel Cards</NavLink></p>
            
          </>
        )}
        {location.pathname.includes("/fuels") && (
          <>
            <p>{">>"}<NavLink to="/fuels">Fuels</NavLink></p>
            
          </>
        )}
        {location.pathname.includes("*") && (
            <><p>{">>"}<NavLink to="*">404 Error</NavLink></p></>
        )}
      </div>
    </div>
  );
};

export default BreadCrumbs;
