import React, { useEffect, useState } from "react";
import { fetchFuelCard } from "../util/fetch";
import styles from "./pages.module.css";
import { NavLink } from "react-router-dom";
import { SITE_URL } from "../config/config";
import { Helmet } from "react-helmet";

const FuelCards = () => {
  const [fuelCardData, setFuelCardData] = useState({
    bannerData: {},
    fuelCard: [],
  });
  useEffect(() => {
    document.title = "Fuel 1 Card | Budget Petrol";
    const fetchData = async () => {
      const response = await fetchFuelCard();
      setFuelCardData({
        ...fuelCardData,
        bannerData: response.data.banner,
        fuelCard: response.data.fuelCard,
      });
    };
    fetchData();
  }, []);
  return (
    <>
      <Helmet>
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Fuel 1 Card | Budget Petrol" />
      <meta property="og:url" content={`${SITE_URL}fuel-1-card`} />
      <meta property="og:site_name" content="BUDGET PETROL" />
      <meta property="article:modified_time" content="2024-05-22T06:01:40+00:00" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:label1" content="Est. reading time" />
      <meta name="twitter:data1" content="1 minute" />
      </Helmet>
      {/* Banner Section */}
      <div
        className={styles.inner_banner_section}
        style={{
          backgroundImage: `url(${fuelCardData.bannerData.banner_image})`,
        }}
      >
        <div className={styles.container}>
          <div className={styles.banner_heading}>
            <h1 className={styles.banner_txt}>
              {fuelCardData.bannerData.banner_title}
            </h1>
            <div className={styles.breadcrumb_div}>
              <p>
                <NavLink to="/">Home </NavLink>
                <span>Fuel 1 Card</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Fuel 1 Card */}
      <div className={styles.fuelCard_section}>
        <div className={`${styles.card_heading}`}>
          {fuelCardData.fuelCard.length > 0 && (
            <h2>
              {fuelCardData.fuelCard[0].bbs_title.slice(0, 7)}
              <span>{fuelCardData.fuelCard[0].bbs_title.slice(7)}</span>
            </h2>
          )}
        </div>
        <div className={styles.card_main_integration}>
          {fuelCardData.fuelCard.length > 0 && (
            <>
              <div className={styles.container}>
                <div className={styles.integration}>
                  <div className={styles.integration_left}>
                    <img src={fuelCardData.fuelCard[0].bbs_image} />
                  </div>
                  <div className={styles.integration_right}>
                    <p>{fuelCardData.fuelCard[0].bbs_content.slice(63, 177)}</p>
                    <ul>
                      <li>
                        {fuelCardData.fuelCard[0].bbs_content.slice(181, 338)}
                      </li>
                      <li>
                        {fuelCardData.fuelCard[0].bbs_content.slice(342, 421)}
                      </li>
                      <li>
                        {fuelCardData.fuelCard[0].bbs_content.slice(425, 589)}
                      </li>
                      <li>
                        {fuelCardData.fuelCard[0].bbs_content.slice(593, 636)}
                      </li>
                    </ul>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: fuelCardData.fuelCard[0].bbs_content.slice(640),
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* Get Started Section */}
      <div
        className={`${styles.getStarted_section} ${styles.budget_boost_section}`}
      >
        <div className={styles.container}>
          {fuelCardData.fuelCard.length > 0 && (
            <>
              <div className={styles.boost_royalty_left}>
                <h2>{fuelCardData.fuelCard[1].bbs_content.slice(3, 95)}</h2>
                <img
                  src={fuelCardData.fuelCard[1].bbs_image}
                  alt="Fuel Card"
                  className={styles.mobile_view}
                />
                <a href="https://www.fuel1.com.au/" target="_blank">
                  <button>Get Started</button>
                </a>
              </div>
              <div className={styles.boost_royalty_right}>
                <img
                  src={fuelCardData.fuelCard[1].bbs_image}
                  alt="Fuel Card"
                  className={styles.desktop_view}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FuelCards;
