import React, { useState, useEffect, useRef } from "react";
import { fetchpartner } from "../util/fetch";
import styles from "./pages.module.css";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setDefaults,
  fromAddress,
  geocode,
  RequestType,
  fromLatLng,
} from "react-geocode";
import { BASE_URL, GOOGLE_MAPS_API_KEY } from "../config/config";
import {
  validateAddress,
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePhone,
  validateComment,
} from "../config/validation";
import BudgetInfoModal from "../components/BudgetInfoModal";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY, SITE_URL } from "../config/config";
import axios from "axios";
import { contactFormAction } from "../store/actions";
import { Helmet } from "react-helmet";

const PartnerWithUS = () => {
  const dispatch = useDispatch();
  const [partnerData, setpartnerData] = useState({
    bannerData: {},
    partner: [],
  });
  const videoRef = useRef(null);
  const recaptcha = useRef();
  const [validationError, setValError] = useState({
    invalidAddress: null,
    invalidEmail: null,
    invalidFirstName: null,
    invalidLastName: null,
    invalidPhone: null,
    invalidComment: null,
  });
  const [recaptchaErr, setCaptchaError] = useState(false);
  const [submitMsg, setMessage] = useState(null);
  const formResponse = useSelector((state) => state.contactResponse);
  useEffect(() => {
    document.title = "Gascorp | Budget Petrol";
    const fetchData = async () => {
      const response = await fetchpartner();
      setpartnerData({
        ...partnerData,
        bannerData: response.data.banner,
        partner: response.data.partner,
        contact: response.data.contact,
      });
    };
    fetchData();
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);
  // Get In Touch Form Handler
  const [touchFormData, setTouchFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    comment: "",
  });
  // *** Form Validation ***
  const validateContact = (data) => {
    const captchaValue = recaptcha.current.getValue();
    if (
      !validateFirstName(data.first_name).success ||
      !validateLastName(data.last_name).success ||
      !validateEmail(data.email).success ||
      !validatePhone(data.phone).success ||
      !validateComment(data.comment).success ||
      !captchaValue
    ) {
      setValError({
        ...validationError,
        invalidFirstName: validateFirstName(data.first_name).message,
        invalidLastName: validateLastName(data.last_name).message,
        invalidEmail: validateEmail(data.email).message,
        invalidPhone: validatePhone(data.phone).message,
        invalidComment: validateComment(data.comment).message,
      });
      if(!captchaValue){
        setCaptchaError(true);
      }
      else{
        setCaptchaError(false)
      }      
      return false;
    } else {
      setValError({
        invalidEmail: null,
        invalidFirstName: null,
        invalidLastName: null,
        invalidPhone: null,
        invalidComment: null,
      });
      setCaptchaError(null);
    }
    return true;
  };
  const handleContactForm = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    if (validateContact(data)) {
      console.log("Data : ", data);
      const response = await axios({
        method: "post",
        url: BASE_URL,
        data: {
          model: "contactForm",
          firstname: data.first_name,
          lastname: data.last_name,
          email: data.email,
          phone: data.phone,
          comment: data.comment,
        },
      });
      console.log("RESPONSE : ",response);
      if (response.data.returnCode === 1) {
        setMessage("Thank you for your message. It has been sent!");
      } else {
        setMessage("Could not deliver your message!");
      }
      setTimeout(() => {
        // setMessage(null);
        recaptcha.current.reset();
        setTouchFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          comment: "",
        });
      }, 1500);
      // dispatch(contactFormAction(data));
      // }
    }
    else{
      setMessage("");
    }
  };
  const [fnameFocus, setFnameFocus] = useState(false);
  const [lnameFocus, setLnameFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);
  const [commentFocus, setCommentFocus] = useState(false);

  return (
    <>
      <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Gascorp | Budget Petrol" />
        <meta property="og:url" content={`${SITE_URL}gascorp`} />
        <meta property="og:site_name" content="BUDGET PETROL" />
        <meta property="article:modified_time" content="2024-05-22T06:01:40+00:00" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="1 minute" />
      </Helmet>
      {/* Banner Section */}
      <div
        className={`${styles.inner_banner_section} ${styles.banner_vid_sec}`}
        // style={{
        //   backgroundImage: `url(${partnerData.bannerData.banner_image})`,
        // }}
      >
        <div className={styles.video_section}>
          <video
            loop
            autoPlay
            muted
            playsInline
            ref={videoRef}
            className={styles.video_content}
          >
            <source
              src="https://www.budgetpetrol.vjbtestwebsites.com/backend/uploads/partner_with_us.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div className={`${styles.container} ${styles.container_vid_div}`}>
          <div className={styles.banner_heading}>
            <h1 className={styles.banner_txt}>
              {partnerData.bannerData.banner_title}
            </h1>
            <div className={styles.breadcrumb_div}>
              <p>
                <NavLink to="/">Home </NavLink>
                <span>Gascorp</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Partner with us Section  */}
      <div className={`${styles.partner_me} ${styles.community_banner_down} ${styles.gascorp_image}`}>
        <div className={styles.container}>
          <div
            className={`${styles.fuel_heading} ${styles.convenience_banener_heading}`}
          >
            <h2 className={`${styles.fuel_txt} ${styles.our_convenince}`}>
            GASCORP – Our Wholesale Team 
              {/* Partner <span>With Us </span> */}
            </h2>
          </div>
          <div className={styles.partner_images}>
            {partnerData.partner.length > 0 && (
              <>
                <div className={styles.partner_img_left}>
                  <img
                    src={partnerData.partner[0].bbs_box[0]}
                    alt="Gift Card"
                  />
                </div>
                {/* <div className={styles.partner_img_middle}>
                  <img src={partnerData.partner[0].bbs_box[1]} alt="Spot US" />
                </div>
                <div className={styles.partner_img_right}>
                  <img src={partnerData.partner[0].bbs_box[2]} alt="Spot US" />
                </div> */}
              </>
            )}
          </div>

          {partnerData.partner && partnerData.partner.length > 0 && (
            <p
              className={styles.shop_local_txt}
              dangerouslySetInnerHTML={{
                __html: partnerData.partner[0].story_content || "",
              }}
            ></p>
          )}
        </div>
      </div>

      {/* Get In Touch Section */}
      <div className={styles.getInTouch_section}>
        <div className={styles.container}>
          <div className={styles.getInTouch_form}>
            <div>
              <h2>Get In Touch</h2>
            </div>
            <div className={styles.input_form}>
              <form onSubmit={handleContactForm}>
                <div className={styles.name_field}>
                  <div
                    className={
                      fnameFocus
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.first_name_field}`
                        : touchFormData.firstName !== ""
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                        : `${styles.first_name_field}`
                    }
                  >
                    <label>
                      First Name <em>*</em>
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      value={touchFormData.firstName}
                      onFocus={() => setFnameFocus(true)}
                      onBlur={() => setFnameFocus(false)}
                      onChange={(e) =>
                        setTouchFormData({
                          ...touchFormData,
                          firstName: e.target.value,
                        })
                      }
                      className={
                        validationError.invalidFirstName && styles.error
                      }
                    />
                    {validationError.invalidFirstName && (
                      <p className={styles.error_txt}>
                        {validationError.invalidFirstName}
                      </p>
                    )}
                  </div>
                  <div
                    className={
                      lnameFocus
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.last_name_field}`
                        : touchFormData.lastName !== ""
                        ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                        : `${styles.last_name_field}`
                    }
                  >
                    <label>
                      Last Name<em>*</em>
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      value={touchFormData.lastName}
                      onFocus={() => setLnameFocus(true)}
                      onBlur={() => setLnameFocus(false)}
                      onChange={(e) =>
                        setTouchFormData({
                          ...touchFormData,
                          lastName: e.target.value,
                        })
                      }
                      className={
                        validationError.invalidLastName && styles.error
                      }
                    />
                    {validationError.invalidLastName && (
                      <p className={styles.error_txt}>
                        {validationError.invalidLastName}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  className={
                    emailFocus
                      ? `${styles.input_focus} ${styles.value_focus} ${styles.email_name_field}`
                      : touchFormData.email !== ""
                      ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                      : `${styles.email_name_field}`
                  }
                >
                  <label>
                    Email<em>*</em>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={touchFormData.email}
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
                    onChange={(e) =>
                      setTouchFormData({
                        ...touchFormData,
                        email: e.target.value,
                      })
                    }
                    className={validationError.invalidEmail && styles.error}
                  />
                  {validationError.invalidEmail && (
                    <p className={styles.error_txt}>
                      {validationError.invalidEmail}
                    </p>
                  )}
                </div>
                <div
                  className={
                    phoneFocus
                      ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                      : touchFormData.phone !== ""
                      ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                      : `${styles.phone_field}`
                  }
                >
                  <label>
                    Phone<em>*</em>
                  </label>
                  <input
                    type="number"
                    name="phone"
                    value={touchFormData.phone}
                    onFocus={() => setPhoneFocus(true)}
                    onBlur={() => setPhoneFocus(false)}
                    onChange={(e) =>
                      setTouchFormData({
                        ...touchFormData,
                        phone: e.target.value,
                      })
                    }
                    className={validationError.invalidPhone && styles.error}
                  />
                  {validationError.invalidPhone && (
                    <p className={styles.error_txt}>
                      {validationError.invalidPhone}
                    </p>
                  )}
                </div>
                <div
                  className={
                    commentFocus
                      ? `${styles.input_focus} ${styles.value_focus} ${styles.comment_field}`
                      : touchFormData.comment !== ""
                      ? `${styles.input_focus} ${styles.value_focus} ${styles.phone_field}`
                      : `${styles.comment_field}`
                  }
                >
                  <label>
                    Comment<em>*</em>
                  </label>
                  <textarea
                    name="comment"
                    value={touchFormData.comment}
                    onFocus={() => setCommentFocus(true)}
                    onBlur={() => setCommentFocus(false)}
                    onChange={(e) =>
                      setTouchFormData({
                        ...touchFormData,
                        comment: e.target.value,
                      })
                    }
                    className={validationError.invalidComment && styles.error}
                  />
                  {validationError.invalidComment && (
                    <p className={styles.error_txt}>
                      {validationError.invalidComment}
                    </p>
                  )}
                </div>
                <div className={styles.captcha_field}>
                  <ReCAPTCHA sitekey={SITE_KEY} ref={recaptcha} className={recaptchaErr && styles.recaptcha_error}/>
                  {recaptchaErr && (
                    <p className={styles.error_txt}>Verification is required!</p>
                  )}
                </div>
                <div className={styles.submit_field}>
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
          {submitMsg && (
            <div className={styles.sent_msg_section}>
              <p className={styles.submit_msg_section}>{submitMsg}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PartnerWithUS;
